<template>
  <Layout>
    <div class="bsat__container">
      <div class="bsat__title">{{ $t("pageTitle") }}</div>
      <section>
        <v-card class="elevation-1">
          <v-card-title>Phase 1</v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="phase1"
            hide-default-footer
            :mobile-breakpoint="0"
          >
            <template #item.download="{ item }">
              <v-row align="center" justify="center">
                <a
                  v-if="item.download && item.download.file"
                  :href="item.download.file"
                  target="_blank"
                  download
                >
                  <v-btn
                    class="bsat__btn mb-0"
                    color="dark"
                    dark
                    depressed
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    >{{ $t("download") }}</v-btn
                  >
                </a>
                <a
                  v-if="item.download && item.download.visit"
                  :href="item.download.visit"
                  target="_blank"
                  class="mt-4"
                >
                  <v-btn
                    outlined
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    class="bsat__btn"
                    color="dark"
                  >
                    {{ $t("visit") }}
                  </v-btn>
                </a>
                <router-link
                  v-if="item.download && item.download.link"
                  :to="item.download.link"
                >
                  <v-btn
                    outlined
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    class="bsat__btn mb-0"
                    color="dark"
                  >
                    {{ $t("visit") }}
                  </v-btn>
                </router-link>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </section>

      <section>
        <v-card class="elevation-1">
          <v-card-title>Phase 2</v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="phase2"
            hide-default-footer
            :mobile-breakpoint="0"
          >
            <template #item.download="{ item }">
              <v-row align="center" justify="center">
                <a
                  v-if="item.download && item.download.file"
                  :href="item.download.file"
                  target="_blank"
                  download
                >
                  <v-btn
                    class="bsat__btn mb-0"
                    color="dark"
                    dark
                    depressed
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    >{{ $t("download") }}</v-btn
                  >
                </a>
                <a
                  v-if="item.download && item.download.visit"
                  :href="item.download.visit"
                  target="_blank"
                  class="mt-4"
                >
                  <v-btn
                    outlined
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    class="bsat__btn"
                    color="dark"
                  >
                    {{ $t("visit") }}
                  </v-btn>
                </a>
                <router-link
                  v-if="item.download && item.download.link"
                  :to="item.download.link"
                >
                  <v-btn
                    outlined
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    class="bsat__btn mb-0"
                    color="dark"
                  >
                    {{ $t("visit") }}
                  </v-btn>
                </router-link>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </section>

      <section>
        <v-card class="elevation-1">
          <v-card-title
            >Common resources for steps in Phases 3 and 4</v-card-title
          >
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="phase3and4"
            hide-default-footer
            :items-per-page="50"
            :mobile-breakpoint="0"
          >
            <!-- <template #item.type="{ item }">
                <code>
                    {{ item }}
                </code>
            </template> -->
            <template #item.download="{ item }">
              <v-row align="center" justify="center">
                <a
                  v-if="item.download && item.download.file"
                  :href="item.download.file"
                  target="_blank"
                  download
                >
                  <v-btn
                    class="bsat__btn mb-0"
                    color="dark"
                    dark
                    depressed
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    >{{ $t("download") }}</v-btn
                  >
                </a>
                <a
                  v-if="item.download && item.download.visit"
                  :href="item.download.visit"
                  target="_blank"
                  class="mt-4"
                >
                  <v-btn
                    outlined
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    class="bsat__btn"
                    color="dark"
                  >
                    {{ $t("visit") }}
                  </v-btn>
                </a>
                <router-link
                  v-if="item.download && item.download.link"
                  :to="item.download.link"
                >
                  <v-btn
                    outlined
                    :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                    height="26"
                    class="bsat__btn mb-0"
                    color="dark"
                  >
                    {{ $t("visit") }}
                  </v-btn>
                </router-link>
                <div v-if="item.download && item.download.files" class="py-2">
                  <ul style="list-style-type: none">
                    <li
                      v-for="(file, i) in item.download.files"
                      :key="i"
                      class="ml-n5"
                    >
                      <a
                        :href="file"
                        target="_blank"
                        download
                        v-if="!item.number"
                      >
                        <v-btn
                          class="bsat__btn mb-0 my-2"
                          color="dark"
                          dark
                          depressed
                          :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                          height="26"
                          >{{ file.split("/files/")[1] }}</v-btn
                        >
                      </a>

                      <a :href="file" target="_blank" download v-else>
                        <v-btn
                          class="bsat__btn mb-0 my-2"
                          color="dark"
                          dark
                          depressed
                          :width="$vuetify.breakpoint.xsOnly ? 100 : 150"
                          height="26"
                          >Download</v-btn
                        >
                      </a>
                    </li>
                  </ul>
                </div>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </section>
    </div>
  </Layout>
</template>

<i18n>
{
	"en": {
		"pageTitle": "Panorama of resources",
		"resourceType": "Resource Type",
		"title": "Title"
	}
}
</i18n>

<script>
import Layout from "@/layouts/default";
import resources from "@/utils/resources";
export default {
  components: {
    Layout
  },
  data() {
    return {
      phase1: resources.phase1,
      phase2: resources.phase2,
      phase3and4: resources.phase3and4
    };
  },
  computed: {
    headers() {
      return this.$vuetify.breakpoint.xsOnly
        ? [
            {
              text: this.$t("title"),
              value: "title",
              align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
              sortable: false,
              groupable: false
            },
            {
              text: this.$t("download"),
              value: "download",
              align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
              sortable: false,
              groupable: false
            }
          ]
        : [
            {
              text: this.$t("title"),
              value: "title",
              align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
              sortable: false,
              groupable: false
            },
            {
              text: this.$t("resourceType"),
              value: "file_label",
              align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
              sortable: false,
              groupable: false
            },
            {
              text: this.$t("download"),
              value: "download",
              align: this.$vuetify.breakpoint.xsOnly ? "center" : "left",
              sortable: false,
              groupable: false
            }
          ];
    }
  },

  methods: {
    checkIfArray(arr) {
      console.log(arr);
      return arr.isArray();
    }
  }
};
</script>
