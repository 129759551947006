export default {
  phase1: [
    {
      number: "1",
      title:
        "Characterisation questionnaire: Template to collect general information of a region",
      type: "Questionnaire template",
      file_label: "Questionnaire template",
      download: {
        file: require("@/assets/files/1_1_1_Characterization_questionnaire.docx")
          .default
      }
    },
    {
      number: "2",
      title:
        "Policy, incentives and public initiatives checklist for bioeconomy development",
      type: "Checklist file",
      file_label: "Checklist file",
      download: {
        file: require("@/assets/files/1_1_2_1_Policy_incentives_and_public_initiatives_checklist_for_bioeconomy_development.docx")
          .default
      }
    },
    {
      number: "3",
      title: "SAT ‘Self-Assessment Tool’",
      type: "Website",
      file_label: "Website",
      download: {
        visit: "https://ec.europa.eu/growth/tools-databases/escss_en"
      }
    },
    {
      number: "4",
      title: "Social and Environmental checklist",
      type: "Checklist template",
      file_label: "Checklist template",
      download: {
        file: require("@/assets/files/1_1_2_3_Social_and_Environmental_check_list.docx")
          .default
      }
    },
    {
      number: "5",
      title:
        "List of selected existing tools for a complementary analysis of the regional bioeconomy potential",
      type: "Table with information and links to external websites",
      file_label: "Table with information and links to external websites",
      download: {
        link: "/new?phase=1&step=3"
      }
    },
    {
      number: "6",
      title:
        "Template document for a SWOT analysis of the region against seven key factors in Bioeconomy",
      type: "Questionnaire template",
      file_label: "Questionnaire template",
      download: {
        file: require("@/assets/files/1_1_4_SWOT_template_per_factor.docx")
          .default
      }
    }
  ],
  phase2: [
    {
      number: "7",
      title:
        "Guideline for Involvement of stakeholders through Regional Bioeconomy Hubs (RBHs)",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/1_2_1_Establishing_Bioeconomy_Hubs_as_stakeholder_platforms_to_support_Regional_Bioeconomy_Strategies.docx")
          .default
      }
    }
  ],
  phase3and4: [
    // {
    //   number: "8",
    //   title:
    //     "Guideline for strategy and roadmap development for more detailed information about the execution of Phase 3 and Phase 4",
    //   type: "PDF",
    //   download: {
    //     // TODO: 1.3.1_1
    //     file: ""
    //   }
    // },
    {
      number: "9",
      title: "Key performance indicators",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/1_3_2_1_Key_performance_indicators_to_characterize_the_regional_bioeconomy.docx")
          .default
      }
    },
    {
      number: "10",
      title: "Catalogue of bio-based solutions",
      type: "Website",
      file_label: "Website",
      download: {
        visit: "https://www.bio-based-solutions.eu/#/"
      }
    },
    {
      number: "11",
      title: "Best practice examples of bio-based business models",
      type: "PDF dossier",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/1_3_2_2_Best_practice_examples_of_bio_based_solutions_with_replicability_potential.docx")
          .default
      }
    },
    {
      number: "12",
      title:
        "Repository of Supporting documents and tools for regional policies in the field of bioeconomy",
      type: "New menu in BSAT",
      file_label: "New menu in BSAT",
      download: {
        //file: require("@/assets/files/financial_tools.docx").default
        visit: "/repository-of-regional-policies-to-support-bio-based-models"
      }
    },
    {
      number: "12.1",
      title: "Policy Overview (EU Policy overview in the area of Bioeconomy)",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/regionalPolicies/RP1_EU_policy_instruments_for_bioeconomy_development_and_link_to_wider_sustainability_ambitions.docx")
          .default
      }
    },
    {
      number: "12.4",
      title: "Selected 10 good policy examples",
      type:
        "Table with information and links to download 10 PDFs with the description of each case",
      file_label:
        "Table with information and links to download 10 PDFs with the description of each case",
      download: {
        file: require("@/assets/files/regionalPolicies/RP2_Policy_Factsheet_1_Austrian_landfill_tax_and_Waste_Prevention_Programmes.docx")
          .default
      }
    },
    {
      number: "12.5",
      title: "Create your good policy in your region",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/regionalPolicies/RP3_Create_your_good_policy_in_your_region.docx")
          .default
      }
    },
    {
      number: "12.6",
      title:
        "General barriers and opportunities for policies in the bioeconomy field",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/regionalPolicies/RP4_General_barriers_and_opportunities_for_policies_in_bioeconomy.docx")
          .default
      }
    },
    {
      number: "12.7",
      title:
        "Recommendations for the development of tailor-made policy instruments to boost bioeconomy trends",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/regionalPolicies/RP5_Recommendations_for_the_development_of_tailor_made_policy_instruments_to_boost_bioeconomy.docx")
          .default
      }
    },
    {
      number: "13",
      title:
        "Repository of supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy",
      type:
        "Visit the repository of supporting documents, or download each file separately from the available list bellow ",
      file_label:
        "Visit the repository of supporting documents, or download each file separately from the available list bellow",
      download: {
        link: "/repository-of-financing-instruments-and-tools"
        // file: require("@/assets/files/financial_tools.docx").default
      }
    },
    {
      number: "13.1",
      title: "Financial tools overview of interest to Bioeconomy",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/financialTools/FP1_F_UNDING_INSTRUMENTS_FOR_INNOVATIVE_BIO_BASED_SOLUTIONS.pdf")
          .default
      }
    },
    {
      number: "13.2",
      title: "Financial Instruments: General concepts ",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/financialTools/FP2_FINANCIAL_I_NSTRUMENTS_GENERAL_CONCEPTS.pdf")
          .default
      }
    },
    {
      number: "13.3",
      title: "European Structural and Investment Funds ",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/financialTools/FP3_European_Structural_and_Investment_Funds.pdf")
          .default
      }
    },
    {
      number: "13.4",
      title:
        "Questionnaire on Financial Instruments: Create your good financial schemes in your region",
      type: "Questionnaire template",
      file_label: "Questionnaire template",
      download: {
        file: require("@/assets/files/financialTools/FP4_Create_your_good_financial_schemes_in_your_region.docx")
          .default
      }
    },
    {
      number: "13.5",
      title: "Examples of financial instruments from EU regions and countries",
      type:
        "39 fiches (PDF) grouped in 6 categories to download/consult by filtering through these categories",
      file_label:
        "39 fiches (PDF) grouped in 6 categories to download/consult by filtering through these categories",
      download: {
        files: [
          require("@/assets/files/financialTools/FP5_Financial_Instruments_Equity.pdf")
            .default,
          require("@/assets/files/financialTools/FP6_Financial_Instruments_Quasi_Equity.pdf")
            .default,
          require("@/assets/files/financialTools/FP7_Financial_Instruments_Loans.docx")
            .default,
          require("@/assets/files/financialTools/FP8_1_Financial_Instruments_Guarantees.docx")
            .default,
          require("@/assets/files/financialTools/FP9_Grants.docx").default,
          require("@/assets/files/financialTools/FP10_Blended_Finance.docx")
            .default
        ]
      }
    },
    {
      number: "13.7",
      title: "Synergies between different funding programmes",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/financialTools/FP11_Synergies_between_different_funding_programmes.docx")
          .default
      }
    },
    {
      number: "14",
      title:
        "Brief list of Recommendations to create finantial schemes for bioeconomy",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/financialTools/FP12_General_Recommendations.docx")
          .default
      }
    },
    {
      number: "15",
      title: "Canvas Model methodology in the field of bioeconomy ",
      type: "PDF",
      file_label: "Document File",
      download: {
        file: require("@/assets/files/1_3_3_1_POWER4BIO_Business_Modelling_Methodology.docx")
          .default
      }
    },
    {
      number: "18.1",
      title: "Training in Bioeconomy sectors ",
      type: "New menu in BSAT",
      file_label: "New menu in BSAT",
      download: {
        link: "/training-in-bioeconomy-sectors"
      }
    }
  ]
};
